import React, { useState } from 'react';
import { QueueClient, QueueSASPermissions } from "@azure/storage-queue";
import './App.css';

function App() {
  const [url, setUrl] = useState('');
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const emailPattern = /^\S+@\S+\.\S+$/; // Corrected regex
    if (!email.match(emailPattern)) {
      alert("Please enter a valid email address.");
      return;
    }

    const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
    if (!url.match(urlPattern)) {
      alert("Please enter a valid URL.");
      return;
    }

    setSubmitted(false); // If the user clicked submit after making one video

    setError(false);

    setLoading(true); // Start loading before sending the request


    // Wait for 1 second to simulate loading, then send the message to the Azure Storage Queue
    setTimeout(async () => {
      try {
        const connectionString = "BlobEndpoint=https://wikipediavideomaker.blob.core.windows.net/;QueueEndpoint=https://wikipediavideomaker.queue.core.windows.net/;FileEndpoint=https://wikipediavideomaker.file.core.windows.net/;TableEndpoint=https://wikipediavideomaker.table.core.windows.net/;SharedAccessSignature=sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-03-09T12:06:42Z&st=2024-04-12T03:06:42Z&spr=https&sig=T31ZQG052KB%2FHDnSYdkBW7JvdZdHqzK84i40q8DWlQQ%3D";
        const queueName = "wikipediavideomaker-queue";
        const sasToken = "sp=racw&st=2024-04-12T02:44:33Z&se=2078-09-18T10:44:33Z&spr=https&sv=2022-11-02&sr=c&sig=g%2F6t%2FdQuMcOTqdjK71YvnZjpZlW211IDD1L433BWchQ%3D";

        const credential = new QueueSASPermissions(sasToken);
        const queueClient = new QueueClient(connectionString, queueName, credential);
  
        const message = {
          email: email,
          url: url,
        };
  
        await queueClient.sendMessage(JSON.stringify(message));
  
        console.log("Message sent to the queue successfully.");
        setLoading(false);
        setSubmitted(true);
      } catch (error) {
        console.error("Error:", error);
        alert("An error occurred while submitting the request.");
        setLoading(false);
        setError(true);
      }
    }, 1000);
  };

  return (
    <div>
    <div className="app">
      <img src={"Nervous_Labs_Logo_No_Background.png"} width={125} height={125} alt="Logo"/>
      <div className="headers">
        <h1>Nervous Labs</h1>
        <h2>Video Maker</h2>
      </div>
    </div>
    <div className="container">
      <h1>Wikipedia Articles</h1>
      <form onSubmit={handleSubmit}>
        <label htmlFor="url">Enter the URL of the Wikipedia Article you want a video on:</label>
        <input
          type="url"
          id="url"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          required
        />
        <label htmlFor="email">Enter your Email Address:</label>
        <p style={{ fontSize: '12px', marginTop: '2px' }}>We may reach out for feedback. We do not sell your email :)</p>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button type="submit">Submit</button>
      </form>
      {loading && <div>Loading...</div>}
      {error && <div>There was an error with submitting your request. This is likely due to high volume. Please contact nathansparker26@gmail.com for assistance.</div>}
      {submitted && <p>Thank you for using the Wikipedia Video Maker! The video will be sent to your email when finished processing.</p>}
      <h2>How to Use</h2>
      <video src="NervousLabs Video Maker How To Video.mp4" controls width="300"></video>
    </div>
    </div>
  );
}
export default App;
